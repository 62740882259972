import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Title2 } from '../Font';

const PointspotAlertTwoFaChange = () => {
    const store_permission = useSelector(state => state.permission);
    const { dictionary } = useSelector(state => state.language);

    return (
        <Modal isOpen={store_permission.isChangeTwoFa} backdrop="static" centered style={{maxWidth: '400px', textAlign: 'center'}}>
            <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Title2 style={{margin:'50px 0 50px 0'}}>{dictionary.permission_changed}</Title2>
                <div id="divider" style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#E0E0E0'
                }}></div>
                <Button 
                    style={{
                        backgroundColor: '#546173',
                        color: 'white',
                        border: 'none',
                        padding: '5px 15px',
                        marginTop: '10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '11px'
                    }}
                    
                    onClick={() => location.reload()}
                >
                    {dictionary.ok}
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default PointspotAlertTwoFaChange;