import axios from './../axios_request';

import { businessAPI, publicAPI } from '../end_point'
import {
  GET_PRIVILEGE_AND_INVITE_LIST,
  PRIVILEGE_AND_INVITE_LIST_IS_PROCESS,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_FAIL,
  ADD_INVITATION_SUCCESS,
  ADD_INVITATION_FAIL,
  CHANGE_USER_PRIVILEGE_SUCCESS,
  CHANGE_USER_PRIVILEGE_FAIL,
  ADD_CUSTOM_ROLE_SUCCESS,
  ADD_CUSTOM_ROLE_FAIL,
  EDIT_CUSTOM_ROLE_SUCCESS,
  EDIT_CUSTOM_ROLE_FAIL
} from '../actions_const'
import { getCookie } from '../../libs/cookies';

export const getPrivilegeAndInvite = (business_code, callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: businessAPI + '/' + business_code + '/admin',
      headers: { Authorization: getCookie("_token") }
    }).then(data => {
      if (typeof callback === 'function') callback(null, data)
      return dispatch({ type: GET_PRIVILEGE_AND_INVITE_LIST, data: data.data })
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
    })
  }
}

export const removeUserInvitationAndPrivilege = ({ action, business_code, data }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: PRIVILEGE_AND_INVITE_LIST_IS_PROCESS })
    axios({
      method: 'delete',
      url: businessAPI + '/' + business_code + '/admin',
      headers: { Authorization: getCookie("_token") },
      data: { action: action, data: data }
    }).then(({ data }) => {
      if (!data.result)
        return Promise.reject(data.error || 'result error')

      if (isFunc) callback(null, data.data)
      return dispatch({ type: DELETE_INVITATION_SUCCESS, data: data.data })
    }).catch(err => {
      if (isFunc) callback(err)
      return dispatch({ type: DELETE_INVITATION_FAIL })
    })
  }
}

export const addUserInvitation = ({ business_code, email, privilege_group_id, transfer_limit, display_name, mobile_number, language = 'th' }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: PRIVILEGE_AND_INVITE_LIST_IS_PROCESS })
    axios({
      method: 'post',
      url: businessAPI + '/' + business_code + '/admin',
      headers: { Authorization: getCookie("_token") },
      data: {
        email: email,
        privilege_group_id: privilege_group_id,
        transfer_limit: transfer_limit,
        display_name: display_name || '',
        language,
        mobile_number
      }
    }).then(({ data }) => {
      if (!data.result)
        return Promise.reject(data.error || 'result error')

      if (isFunc) callback(null, data.data)
      return dispatch({ type: ADD_INVITATION_SUCCESS, data: data.data })
    }).catch(err => {
      if (isFunc) callback(err)
      return dispatch({ type: ADD_INVITATION_FAIL })
    })
  }
}

export const changeUserPrivilege = ({ business_code, uid, privilege_group_id, transfer_limit, display_name, mobile_number, language = 'th' }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: PRIVILEGE_AND_INVITE_LIST_IS_PROCESS })
    axios({
      method: 'put',
      url: businessAPI + '/' + business_code + '/admin',
      headers: { Authorization: getCookie("_token") },
      data: {
        uid: uid,
        privilege_group_id: parseInt(privilege_group_id, 10),
        transfer_limit: transfer_limit,
        display_name: display_name || '',
        language,
        mobile_number
      }
    }).then(({ data }) => {
      if (!data.result)
        return Promise.reject(data.error || 'result error')

      if (isFunc) callback(null, data.data)
      return dispatch({ type: CHANGE_USER_PRIVILEGE_SUCCESS, data: data.data })
    }).catch(err => {
      if (isFunc) callback(err)
      return dispatch({ type: CHANGE_USER_PRIVILEGE_FAIL })
    })
  }
}

export const addCustomRole = (business_code, {role_name, checkList, list, self_transaction_history}, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: businessAPI + '/' + business_code + '/role',
      headers: { Authorization: getCookie("_token") },
      data: {
        role_name: role_name,
        checkList: checkList,
        list: list,
        self_transactions_history: self_transaction_history
      }
    }).then((data) => {
      data.data.role = data.data.role.map((val) => {
        return Object.assign({}, val , { privilege_group_name: role_name })
      })
      if (isFunc) callback(null, data)
      return dispatch({ type: ADD_CUSTOM_ROLE_SUCCESS, data: data.data })
    }).catch((err) => {
      if (isFunc) callback(err)
      return dispatch({ type: ADD_CUSTOM_ROLE_FAIL })
    })
  }
}

export const editCustomRole = (business_code, { id, checkList, unCheckList, role_name, self_transaction_history }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'put',
      url: businessAPI + '/' + business_code + '/role',
      headers: { Authorization: getCookie("_token") },
      data: {
        privilege_group_id: id,
        checkList: checkList,
        unCheckList: unCheckList,
        role_name: role_name,
        self_transactions_history: self_transaction_history
      }
    }).then((data) => {
      if (isFunc) callback(null, data)
      return dispatch({ type: EDIT_CUSTOM_ROLE_SUCCESS, data: data.data.data || [], dataName: data.data.dataName })
    }).catch((err) => {
      if (isFunc) callback(err)
      return dispatch({ type: EDIT_CUSTOM_ROLE_FAIL })
    })
  }
}

export const getInformationInviteToken = ({email, invite_token}, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: publicAPI + '/getInformationInviteToken',
      data: {
        email,
        invite_token
      }
    }).then(({ data }) => {
      if (isFunc) callback(null, data)
    }).catch(err => {
      window.location.href = '/signin'
      if (isFunc) callback(err)
    })
  }
}