import axios from "./../axios_request";
import * as UserAPI from "../../libs/userApi";
import * as ConsentAPI from "../../libs/consent";

import { privateAPI } from "./../end_point";

import {
  getCookie,
  removeCookie,
  removeCookieWithDomain,
  setCookie,
  setCookieWithDomain,
} from "../../libs/cookies";
import { TRACE, DEBUG, ERROR } from "./../../libs/utils";
import { FB_Firestore, signinWithCustomToken } from "./../../libs/Firebase";

import {
  getFirebaseApp,
  getUserProfile,
  signinWithCustomToken as signinAcountWithCustomToken,
  signout,
  updateUserStatus,
  signin,
  getProfileOnSnapshot,
  setPersistence,
} from "./../../libs/FirebaseAccount";
import Caching from "./../../libs/caching";

import {
  MEMBER_IS_LOGIN,
  MEMBER_IS_LOGOUT,
  GET_SITE_CONFIG,
  PERMISSION,
  IS_CREATE_BUSINESS,
} from "../actions_const";
import { setToken, setUUID } from "../../libs/token";
import _ from "lodash";
import Cookies from "js-cookie";
import sha1 from "sha1";
const TAG = "a-permission";
let updateTokenTimeout;

const updateTokenWithTimeout = () => {
  updateTokenTimeout = setTimeout(() => {
    setToken();
    DEBUG(TAG, "[Renew Token]", new Date().toLocaleString());

    updateTokenWithTimeout();
  }, 3300000);
};

export const onAuthStateChanged = (callback) => {
  return async (dispatch, getState) => {
    const fbApp = getFirebaseApp();
    fbApp.auth().onAuthStateChanged(async (user) => {
      DEBUG("[onAuthStateChanged]", !user);
      if (!user) {
        removeCookieWithDomain("__customToken");
        removeCookie("__bnc");
        removeCookie("_email");
        removeCookie("_token");
        clearTimeout(updateTokenTimeout);
        return dispatch({ type: PERMISSION.USER_LOGOUT_SUCCESS });
      }

      await setToken();
      updateTokenWithTimeout();
      if (typeof callback === "function") {
        callback(null, user);
      }
    });
  };
};

export const getConfig = (callback) => {
  let TAGm = TAG + ".getConfig";
  let isCollection = "site_config";

  return (dispatch) => {
    let collection = FB_Firestore.collection(isCollection)
      .orderBy("create_date")
      .limit(1);
    collection.onSnapshot(
      (snapshot) => {
        DEBUG(TAGm, "Get Site Config");
        let data =
          (snapshot &&
            snapshot.docs &&
            snapshot.docs[0] &&
            snapshot.docs[0].data()) ||
          {};

        if (typeof callback === "function") callback(null, data);
        return dispatch({
          type: GET_SITE_CONFIG,
          data: {
            ...data,
            create_date: isNaN(Number(data.create_date)) || !data.create_date
              ? null
              : new Date(Number(data.create_date) * 1000).toISOString(),
          },
        });
      },
      (error) => {
        ERROR(TAGm, error);
      }
    );
  };
};

export const checkLogin = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let TAGm = TAG + ".verifyToken";
      let token = getCookie("_token");
      let isVerify = getState().permission.isVerify;
      let authAccount = getState().permission.authAccount;
      let isUpdateToken = getState().permission.isUpdateToken;
      let isLogin = getState().permission.isLogin;
      let uuid = getCookie("_uuid");
      if (!uuid) {
        uuid = setUUID();
      }

      DEBUG(TAGm, "Check Login", token);

      axios({
        method: "post",
        url: privateAPI + "/auth/verifyToken",
        headers: { authorization: `Bearer ${token}` },
        data: {
          uuid: uuid,
        },
      })
        .then((response) => {
          let data = response.data;

          if (!data.result) {
            removeCookie("_email");
            return dispatch({ type: MEMBER_IS_LOGOUT });
          }

          DEBUG("[checkLogin]", _.get(data.user, ["email"]), getCookie("_email"));

          if (
            getCookie("_email") &&
            _.get(data.user, ["email"]) !== getCookie("_email")
          ) {
            removeCookie("_email");
            return dispatch({
              type: PERMISSION.USER_LOGIN_FAIL,
              error: new ERROR("Email mismatch."),
            });
          }

          let { customToken } = data;
          let accountCustomToken = data.accountCustomToken;

          DEBUG(TAGm, "Receive Custom Token");

          signinWithCustomToken(customToken)
            .then(({ user }) => {
              const paramsUrl = new URLSearchParams(window.location.search);
              const invite_token = paramsUrl.get("invite_token");
              return (invite_token && user?.uid)
                ? UserAPI.verifyAdminInvite(invite_token, user.uid).then(() => user)
                : Promise.resolve(user);
            })
            .then(async (user) => {
              Caching.setCaching("uid", user.uid);
              DEBUG(TAGm, "Signin UID", user.uid);
              TRACE(
                TAGm,
                "Last Signin",
                new Date(user.metadata.lastSignInTime).toLocaleString()
              );

              if (!isLogin) {
                let tabUUID = getState().permission.tabUUIDStore;
                updateUserStatus(true, tabUUID);
              }

              if (!authAccount) {
                try {
                  const accountData = await signinAcountWithCustomToken(
                    accountCustomToken
                  );
                  isVerify = (accountData.user || accountData).emailVerified;
                  authAccount = true;
                  setCookieWithDomain("__customToken", accountCustomToken);
                } catch (error) {
                  ERROR(
                    TAGm,
                    "signinAcountWithCustomToken",
                    error.stack || error.message || error
                  );
                  Caching.clearCaching("uid");
                  removeCookieWithDomain("__customToken");
                  removeCookie("_email");
                  return dispatch({ type: MEMBER_IS_LOGOUT });
                }
              } else if (isUpdateToken) {
                await signinAcountWithCustomToken(accountCustomToken);
                await setToken();
              }

              dispatch({
                type: MEMBER_IS_LOGIN,
                data: Object.assign({}, data.user, {
                  isVerify: isVerify,
                  authAccount: authAccount,
                }),
              });

              resolve();
            })
            .catch((error) => {
              ERROR(TAGm, "signinWithCustomToken", error.stack || error.message || error);
              dispatch({ type: MEMBER_IS_LOGIN, data: Object.assign({}, {}) });
              dispatch({ type: GET_SITE_CONFIG, data: {} });
              reject(error);
            });

        })
        .catch((error) => {
          ERROR(TAGm, error.stack || error.message || error);
          Caching.clearCaching("uid");
          removeCookie("_email");
          dispatch({ type: MEMBER_IS_LOGOUT });
          reject(error)
        });
    });
  };
};

const logLogin = async (userToken) => {
  await axios({
    method: 'POST',
    url: privateAPI + '/loginLog',
    data: {
      userToken: userToken
    }
  });
}

export const loginWithEmailAndPassword = async (email, password) => {
  return (dispatch, getState) => {
    let tabUUIDStore = getState().permission.tabUUIDStore;
    dispatch({ type: PERMISSION.CLEAR_ERROR });
    setCookie("_email", email);
    let passWord = password.trim();
    let passhash = sha1(passWord);
    setPersistence()
      .then(() => {
        return signin(email, passhash);
      })
      .then((user) => {
        const paramsUrl = new URLSearchParams(window.location.search);
        const invite_token = paramsUrl.get("invite_token");

        return (invite_token && user?.user?.uid)
          ? UserAPI.verifyAdminInvite(invite_token, user.user.uid)
            .then(() => user)
            .catch((err) => {
              console.error("verifyAdminInvite failed:", err);
              return user;
            })
          : Promise.resolve(user);
      })
      .then((user) => {
        dispatch({ type: PERMISSION.USER_LOGIN_REQUEST });

        return getUserProfile(user.user)
          .then(async (data) => {
            const userToken = await user.user.getIdToken();
            logLogin(userToken);
            updateUserStatus(true, tabUUIDStore);
            dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data });
          })
          .catch((error) => {
            removeCookie("_email");
            dispatch({
              type: PERMISSION.USER_LOGIN_FAIL,
              error: "error_occurred",
            });
          });
      })
      .catch((error) => {
        let error_message = "";

        if (error && error.code !== "auth/too-many-requests") {
          return signin(email, passWord)
            .then((user) => {
              dispatch({ type: PERMISSION.USER_LOGIN_REQUEST });

              return getUserProfile(user.user)
                .then((data) => {
                  updateUserStatus(true, tabUUIDStore);
                  dispatch({
                    type: PERMISSION.USER_LOGIN_SUCCESS,
                    data: data,
                  });
                })
                .catch((error) => {
                  removeCookie("_email");
                  dispatch({
                    type: PERMISSION.USER_LOGIN_FAIL,
                    error: "error_occurred",
                  });
                });
            })
            .catch((error) => {
              removeCookie("_email");

              if (
                error.code === "auth/wrong-password" ||
                error.code === "auth/user-not-found"
              ) {
                error_message = "incorrect_email_or_password";
              } else if (error.code === "auth/too-many-requests") {
                error_message = "too_many_attempts";
              } else {
                error_message = "error_occurred";
              }

              dispatch({
                type: PERMISSION.USER_LOGIN_FAIL,
                error: error_message,
              });
            });
        } else {
          removeCookie("_email");
          error_message = "too_many_attempts";

          dispatch({
            type: PERMISSION.USER_LOGIN_FAIL,
            error: error_message,
          });
        }
      });

  };
};

export const cancelAuthStateChange = () => {
  return (dispatch) => {
    dispatch({ type: PERMISSION.USER_LOGOUT_SUCCESS })
  }
}

export const userLogout = () => {
  //Object.assign(window.ps_auth, { uid: null })
  return (dispatch) => {
    updateUserStatus(false)
      .then(() => signout())
      .then(() => {
        removeCookieWithDomain("__customToken");
        removeCookie("__bnc");
        removeCookie("_tabUUID")
        clearTimeout(updateTokenTimeout);
        dispatch({ type: PERMISSION.USER_LOGOUT_SUCCESS });
      })
      .catch((err) => {
        ERROR("userLogout", err && err.message);
      });
  };
};

export const removeSocialCredential = () => {
  return (dispatch) => {
    dispatch({ type: PERMISSION.REMOVE_SOCIAL_CREDENTAIL });
  };
};

export const createUserWithEmail = (data, providerData, onErrorCallback) => {
  let _tkn;
  let userDisplayName = data.first_name + " " + data.last_name;
  
  return (dispatch) => {
    const fbAuth = getFirebaseApp().auth();
    dispatch({ type: PERMISSION.CREATE_USER_REQUEST });

    fbAuth.createUserWithEmailAndPassword(data.email, data.password)
      .then(({ user }) => {
        return fbAuth.currentUser
          .updateProfile({
            displayName: userDisplayName.trim(),
            password: data.password,
          })
          .then(() => {
            userLinkSocialAccount(providerData);
            return user.getIdToken(true);
          });
      })
      .then((token) => {
        _tkn = token;
        return UserAPI.updatePassword(token, data.password)
          .then(() => {
            return UserAPI.register(
              token,
              Object.assign({}, data, {
                name: data.first_name,
                display_name: userDisplayName,
                business_name: data.business_name,
                business_category: data.business_category,
                product_category: data.product_category,
                tap_ref_code: data.tap_ref_code,
                tap_tracking_id: data.tap_tracking_id,
                current_url: data.current_url,
                referrer_url: data.referrer_url,
                consent_personal: data.consent_personal,
                gclid: data.gclid,
                utm_source: data.utm_source,
                utm_medium: data.utm_medium,
                utm_campaign: data.utm_campaign,
              })
            );
          });
      })
      .then((res) => {
        if (!res.data.result) {
          if (fbAuth.currentUser) fbAuth.currentUser.delete();
          onErrorCallback();
          return dispatch({
            type: PERMISSION.CREATE_USER_FAIL,
            error: { code: "register-fail", message: "Register fail" },
          });
        }

        return UserAPI.sendEmailVerification(_tkn, data.language, data?.invite_code, data?.invite_token)
          .then(() => {
            dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: res.data.data });
            Cookies.remove("__firebase");
          });
      })
      .catch((error) => {
        if (fbAuth.currentUser && error?.code !== "auth/email-already-in-use") fbAuth.currentUser.delete();
        onErrorCallback(error);
        dispatch({ type: PERMISSION.CREATE_USER_FAIL, error: error });
      });
  };
};


export const updateUserWithEmail = (data, token, callback) => {
  return (dispatch, getState) => {
    let tabUUIDStore = getState().permission.tabUUIDStore;
    UserAPI.updateUserProfile(token, data)
      .then((res) => {
        const { uid, customToken } = res.data.data;
        if (uid) ConsentAPI.sendConsent(uid);

        dispatch({ type: PERMISSION.USER_LOGIN_REQUEST });
        signinAcountWithCustomToken(customToken)
          .then((user) => getUserProfile(user.user || user))
          .then((data) => {
            updateUserStatus(true, tabUUIDStore);
            setCookieWithDomain("__customToken", customToken);
            dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data });
          })
          .then(() => {
            let token = getCookie("_token");
            if (!token) {
              token = setToken();
            }
          })
          .catch((error) => {
            dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: error });
          });

        return callback();
      })
      .catch((err) => {
        ERROR("updateUserWithEmail", err && err.message);
      });
  };
};

const userLinkSocialAccount = (providerData) => {
  return new Promise((resolve, reject) => {
    if (providerData) {
      const fbAuth = getFirebaseApp().auth();
      fbAuth.currentUser
        .linkWithCredential(providerData.credential)
        .then(resolve)
        .catch(reject);
    } else resolve();
  });
};

export const sendEmailVerification = (language, invite_token) => {
  return (dispatch) => {
    const fbAuth = getFirebaseApp().auth();
    const user = fbAuth.currentUser;
    user
      .getIdToken()
      .then((token) => UserAPI.sendEmailVerification(token, language, null, invite_token))
      .then((res) => {
        if (!res.data.result) {
          if (res.data.errorMessage.code === "auth/email-already-verified")
            return dispatch({ type: "EMAIL_ALREADY_VERIFIED" });
        }
      })
      .catch((err) => { 
        ERROR("sendEmailVerification", err && err.message);
      });
  };
};

export const getVerifyEmail = (code, callback) => {
  return (dispatch, getState) => {
    let tabUUIDStore = getState().permission.tabUUIDStore;
    let uuid = getCookie('_uuid');
    UserAPI.getVerifyEmail(code, uuid)
      .then((res) => {
        if (!res.data.result) return callback("/");
        const { uid, customToken } = res.data.data;
        if (uid) ConsentAPI.sendConsent(uid);

        // window.ps_auth = res.data.data
        dispatch({ type: "EMAIL_ALREADY_VERIFIED" });
        dispatch({ type: PERMISSION.USER_LOGIN_REQUEST });
        signinAcountWithCustomToken(customToken)
          .then((user) => getUserProfile(user.user || user))
          .then((data) => {
            updateUserStatus(true, tabUUIDStore);
            setCookieWithDomain("__customToken", customToken);
            dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data });
          })
          .then(() => {
            let token = getCookie("_token");
            if (!token) {
              token = setToken();
            }
          })
          .catch((error) => {
            dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: error });
          });
        return callback("/verified");
      })
      .catch(() => {
        return callback("/");
      });
  };
};

export const signinCustomToken = (token, action, callback) => {
  return (dispatch, getState) => {
    let tabUUIDStore = getState().permission.tabUUIDStore;
    dispatch({ type: PERMISSION.USER_LOGIN_REQUEST });
    signinAcountWithCustomToken(token)
      .then((user) => getUserProfile(user.user || user))
      .then((data) => {
        updateUserStatus(true, tabUUIDStore);
        setCookieWithDomain("__customToken", token);
        dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data });
      })
      .then(() => {
        let token = getCookie("_token");
        if (!token) {
          token = setToken();
        }
        if (action === "createBusiness") {
          dispatch({ type: IS_CREATE_BUSINESS });
        }
        return callback("/");
      })
      .catch((error) => {
        dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: error });
        return callback("/signin");
      });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({ type: PERMISSION.CLEAR_ERROR });
  };
};

export const getAccountProfile = (uid) => {
  return (dispatch) => {
    if (!uid) return;
    getProfileOnSnapshot(uid).then((data) => {
      dispatch({ type: PERMISSION.GET_PROFILE_ACCOUNT, data: data });
    });
  };
};

export const setTabUUID = (uuid) => {
  return (dispatch) => {
    dispatch({ type: PERMISSION.SET_TAB_UUID, data: uuid });
  };
}