import { getCookie } from '../../libs/cookies';
import { DEBUG, ERROR } from '../../libs/utils'
import { PACKAGE, PERMISSION } from '../actions_const'
import { businessAPI, privateAPI } from '../end_point'

import axios from './../axios_request';
import { getUsageRecord } from './action_usage';

export const getPackages = (call_back) => {
  const TAG = 'getPackages'
  return dispatch => {
    axios({
      method: 'get',
      url: `${privateAPI}/package`,
      headers: { Authorization: getCookie("_token") }

    }).then(({ data }) => {
      DEBUG('THEN', TAG)
      if (typeof call_back === 'function') call_back(null, data)
      return dispatch({ type: PACKAGE.GET_PACKAGES, data: data })

    }).catch(err => {
      ERROR('CATCH', TAG)
      if (typeof call_back === 'function') call_back(err)
    })
  }
}

export const getBusinessPackage = ({ business_code, load = true }, call_back) => {
  return dispatch => {
    if (load) dispatch({ type: PACKAGE.PACKAGE_IS_PROCESS })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/package`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      DEBUG('THEN getBusinessPackage', business_code)
      if (typeof call_back === 'function') call_back(null, data)
      return dispatch({ type: PACKAGE.GET_BUSINESS_PACKAGE, data: data })
    }).catch(err => {
      ERROR('CATCH getBusinessPackage', business_code, err.message)
      if (typeof call_back === 'function') call_back(err)
      //return dispatch({ type: PACKAGE.GET_BUSINESS_PACKAGE, data: {} })
    })
  }
}

export const getBusinessPackageSSE = ({ business_code, load = false }, call_back) => {
  return (dispatch, getState) => {
    if (load) dispatch({ type: PACKAGE.PACKAGE_IS_PROCESS });

    let eventSourceInstance = null;

    const createEventSource = () => {
      const url = new URL(
        `${businessAPI}/${business_code}/package/sse?authorization=${getCookie('_token')}`
      );
      const eventSource = new EventSource(url);

      eventSource.onopen = () => {
        DEBUG('SSE connection opened for business_code:', business_code);
      };

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          DEBUG('SSE message received in getBusinessSSE', business_code, data);
          if (typeof call_back === 'function') call_back(null, data);
          return dispatch({ type: PACKAGE.GET_BUSINESS_PACKAGE, data });
        } catch (error) {
          ERROR('Failed to parse SSE data', error);
          if (typeof call_back === 'function') call_back(error);
        }
      };

      eventSource.addEventListener('updatePackage', (event) => {
        const data = JSON.parse(event.data);
        dispatch(getBusinessPackage({ business_code: data.business_code }, call_back));
        dispatch(getUsageRecord(business_code, call_back));
      });

      eventSource.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        const uuid = getCookie("_uuid");
       
        if (data.adminSetOtp === true && data.setBy !== uuid && data.typeOtp !== 'off') {
          dispatch({ type: PERMISSION.CHANGE_TWO_FA });
        }
      });
      

      eventSource.onerror = (err) => {
        ERROR('SSE error in getBusinessSSE', business_code, err);
        if (typeof call_back === 'function') call_back(err);
        eventSource.close();
      };

      return eventSource;
    };

    if (eventSourceInstance) {
      eventSourceInstance.close();
      DEBUG('Closed previous SSE connection for business_code:', business_code);
    }

    eventSourceInstance = createEventSource();

    return () => {
      if (eventSourceInstance) {
        eventSourceInstance.close();
        DEBUG('SSE connection closed for business_code:', business_code);
      }
    };
  };
};



export const getBusinessSubscription = (business_code, call_back) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/subscription`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof call_back === 'function') call_back(null, data)
        return dispatch({ type: PACKAGE.GET_BUSINESS_SUBSCRIPTION, data: data.data })
      } else {
        if (typeof call_back === 'function') call_back(data.error)
        return dispatch({ type: PACKAGE.GET_BUSINESS_SUBSCRIPTION, data: {} })
      }
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      return dispatch({ type: PACKAGE.GET_BUSINESS_SUBSCRIPTION, data: {} })
    })
  }
}

export const createBusinessSubscription = (body, call_back) => {
  return dispatch => {
    dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: true })
    axios({
      method: 'post',
      url: `${businessAPI}/${body.business_code}/subscription`,
      headers: { Authorization: getCookie("_token") },
      data: body
    }).then(({ data }) => {
      if (data.result) {
        if (typeof call_back === 'function') call_back(null, data.data)
        return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
      } else {
        return Promise.reject('result error')
      }
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
    })
  }
}

export const cancelBusinessSubscription = (business_code, call_back) => {
  return dispatch => {
    //dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: true })
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/subscription`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof call_back === 'function') call_back(null, data.data)
        //return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
      } else {
        return Promise.reject('result error')
      }
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      //return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
    })
  }
}

export const createBusinessAddonSubscription = ({ business_code, subscription_id, addon }, call_back) => {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${businessAPI}/${business_code}/subscription/addon`,
      headers: { Authorization: getCookie("_token") },
      data: { subscription_id, addon }
    }).then(({ data }) => {
      if (data.result && (typeof call_back === 'function'))
        call_back(null, data)
      else
        return Promise.reject('result error')
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
    })
  }
}