import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { Title1, Title2 } from "../../components/Font";
import { PrimaryBtn } from "../../components/Button";
import OtpSectionEmail from "./OtpSectionEmail";
import {
  resetErrorEmailOTP,
  sendEmailOTP,
  verifyEmailOTP,
} from "../../redux/actions/action_verify_otp";
import { getCookie } from "../../libs/cookies";
import prepareText from "../../libs/prepareText";
import { nl2br } from "../../libs/nl2br";
import { SendAgainText } from "./selectBusiness.style";
import { selectBusinessDropdownlist } from "../../redux/actions/action_business";

const OtpEmailAuthen = (props) => {
  const dispatch = useDispatch();
  const { dictionary, locale } = useSelector((state) => state.language);
  const { profile } = useSelector((state) => state.permission);
  const { list: business_list } = useSelector((state) => state.business);
  const { current } = useSelector((state) => state.business);
  const { emailOTP, cooldown } = useSelector((state) => state.verifyotp);
  const [otp, setOtp] = useState("");

  const { business_info } = props;

  const updateOtp = (otp_code) => {
    dispatch(resetErrorEmailOTP());
    setOtp(otp_code);
  };

  const reqEmailAgain = () => {
    setOtp("");
    dispatch(resetErrorEmailOTP());
    dispatch(sendEmailOTP({ email: profile.email, lang: locale }));
  };

  const verifyOTP = () => {
    let uuid = getCookie("_uuid");
    let business_code = business_info.business_code || current.business_code;
    dispatch(
      verifyEmailOTP(
        {
          email: profile.email,
          refCode: emailOTP?.refCode || "",
          otp: otp,
          uuid,
        },
        (err, res) => {
          if (!err) {
            let businessList = business_list.map((business) => {
              if (business.two_fa.email === true) {
                return {
                  ...business,
                  otp_verify: true,
                };
              }
              return business;
            });
            dispatch({
              type: "UPDATE_VERIFY_EMAIL_OTP_SUCCESS",
              data: businessList,
            });
            dispatch(selectBusinessDropdownlist(business_code));
          }
        }
      )
    );
  };

  const verifyField = () => {
    let result = true;
    if (
      ["oauth2/otp-limit-exceeded", "oauth2/otp-too-many-retry"].includes(
        emailOTP.error?.code
      ) ||
      otp.length !== 6
    ) {
      result = false;
    }

    return !result;
  };

  return (
    <div className="w-100 text-center my-2">
      <div>
        <Title2>
          {parse(
            prepareText(dictionary.confirm_choose_business, [
              business_info.business_name || current.business_name,
            ])
          )}
        </Title2>
        <Title2>
          {parse(prepareText(dictionary.send_to_email, [profile.email]))}
        </Title2>
        <Title2
          hidden={
            emailOTP.error?.code === "oauth2/otp-limit-exceeded" &&
            !emailOTP?.refCode
          }
        >
          {`(${dictionary.verify_ref} - ${emailOTP?.refCode})`}
        </Title2>
      </div>

      {["oauth2/otp-limit-exceeded", "oauth2/otp-too-many-retry"].includes(
        emailOTP.error?.code
      ) ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 172 }}
        >
          <Title1 bold danger>
            {emailOTP.error?.code === "oauth2/otp-limit-exceeded"
              ? nl2br(
                prepareText(dictionary[emailOTP.error?.code], [
                  emailOTP.error?.remain_time || 60,
                ])
              )
              : nl2br(dictionary[emailOTP.error?.code])}
          </Title1>
        </div>
      ) : emailOTP.isLoading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 172 }}
        >
          <i className="fas fa-spinner fa-pulse ml-2" />
        </div>
      ) : (
        <OtpSectionEmail updateOtp={updateOtp} otp={otp} />
      )}

      <PrimaryBtn
        md
        style={{ width: 100 }}
        onClick={() => verifyOTP()}
        disabled={verifyField() || emailOTP.isProcess || emailOTP?.refCode === ""}
      >
        <Title2 bold white>
          {emailOTP.isProcess ? (
            <i className="fas fa-spinner fa-pulse" />
          ) : (
            dictionary.verify_button
          )}
        </Title2>
      </PrimaryBtn>
      <div className="d-flex justify-content-center mt-3">
        <SendAgainText
          disabled={
            cooldown ||
            emailOTP.isProcess ||
            emailOTP.isLoading ||
            emailOTP.error?.code === "oauth2/otp-limit-exceeded"
          }
          mt-2
          onClick={() => reqEmailAgain()}
        >
          {`${dictionary.send_email_again}${cooldown ? ` (${cooldown})` : ""}`}
          {emailOTP.isLoading ? (
            <i className="fas fa-spinner fa-pulse ml-2" />
          ) : (
            ""
          )}
        </SendAgainText>
      </div>
    </div>
  );
}


export default OtpEmailAuthen;