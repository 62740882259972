import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { Title1, Title2 } from "../../components/Font";
import { PrimaryBtn } from "../../components/Button";
import OtpSectionSMS from "./OtpSectionSMS";
import {
  requestOTP,
  resetErrorSMSOTP,
  verifySMSOTPLogin,
} from "../../redux/actions/action_verify_otp";
import { selectBusinessDropdownlist } from "../../redux/actions/action_business";
import prepareText from "../../libs/prepareText";
import { nl2br } from "../../libs/nl2br";
import { SendAgainText } from "./selectBusiness.style";
import { getCookie } from "../../libs/cookies";

const OtpSMSAuthen = (props) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { dictionary } = useSelector((state) => state.language);
  const { list:business_list } = useSelector((state) => state.business);
  const { current } = useSelector((state) => state.business);
  const { isProcess, error, refCode, cooldown } = useSelector((state) => state.verifyotp);
  const [otp, setOtp] = useState("");
  const { business_info } = props;
  const phoneInternational = business_info.phone_otp?.replace(/^0/, '+66');

  const updateOtp = (otp_code) => {
    dispatch(resetErrorSMSOTP());
    setOtp(otp_code);
  };

  const reqSMSAgain = () => {
    setOtp("");
    setLoading(true);
  
    dispatch(requestOTP(phoneInternational, (error, data) => {
      setLoading(false);
      if(data?.refCode) {
        dispatch(resetErrorSMSOTP());
      }
    }));
  };

  const verifySMSOTP = () => {
    let business_code = business_info.business_code || current.business_code;
    let uuid = getCookie("_uuid");
    dispatch(
      verifySMSOTPLogin({ phoneNumber: phoneInternational, refCode: refCode || "", otp, uuid },
        (err, res) => {
          if (!err) {
            let businessList = business_list.map((business) => {
              if (business.business_code === current.business_code || current.phone_otp === business.phone_otp) {
                return {
                  ...business,
                  otp_verify: true,
                };
              }
              return business;
            });
            dispatch({
              type: "UPDATE_VERIFY_EMAIL_OTP_SUCCESS",
              data: businessList,
            });
            dispatch(selectBusinessDropdownlist(business_code));
          }
        }
      )
    );
  };

  const verifyField = () => {
    let result = true;
    if (
      ["oauth2/otp-limit-exceeded", "oauth2/otp-too-many-retry"].includes(
        error?.code
      ) ||
      otp.length !== 5
    ) {
      result = false;
    }

    return !result;
  };

  return (
    <div className="w-100 text-center my-2">
      <div>
        <Title2>
          {parse(
            prepareText(dictionary.confirm_choose_business, [
              business_info.business_name || current.business_name,
            ])
          )}
        </Title2>
        <Title2>
          {parse(prepareText(dictionary.send_to_mobile_phone, [business_info.phone_otp?.replace(/(\d{2})\d{4}(\d{3})/, '$1****$2')]))}
        </Title2>
        <Title2
          hidden={
            error?.code === "oauth2/otp-limit-exceeded" &&
            !refCode
          }
        >
          {`(${dictionary.verify_ref} - ${typeof refCode === 'string' ? refCode : ""})`}
        </Title2>
      </div>
      {["oauth2/otp-limit-exceeded", "oauth2/otp-too-many-retry"].includes(
        error?.code
      ) ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 172 }}
        >
         
          <Title1 bold danger>
            {error?.code === "oauth2/otp-limit-exceeded"
              ? nl2br(
                  prepareText(dictionary[error?.code], [
                    error?.remain_time || 60,
                  ])
                )
              : nl2br(dictionary[error?.code])}
          </Title1>
        </div>
      ) : isProcess ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 172 }}
        >
          <i className="fas fa-spinner fa-pulse ml-2" />
        </div>
      ) : (
        <OtpSectionSMS updateOtp={updateOtp} otp={otp} />
      )}

      <PrimaryBtn
        md
        style={{ width: 100 }}
        onClick={() => verifySMSOTP()}
        disabled={verifyField() || isProcess || refCode === ""}
      >
        <Title2 bold white>
          {isProcess ? (
            <i className="fas fa-spinner fa-pulse" />
          ) : (
            dictionary.verify_button
          )}
        </Title2>
      </PrimaryBtn>
      <div className="d-flex justify-content-center mt-3">
        <SendAgainText
          disabled={
            cooldown ||
            loading ||
            isProcess ||
            error?.code === "oauth2/otp-limit-exceeded"
          }
          mt-2
          onClick={() => reqSMSAgain()}
        >
          {`${dictionary.send_email_again}${cooldown ? ` (${cooldown})` : ""}`}
          {loading ? (
            <i className="fas fa-spinner fa-pulse ml-2" />
          ) : (
            ""
          )}
        </SendAgainText>
      </div>
    </div>
  );
}


export default OtpSMSAuthen;