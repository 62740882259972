import axios from './../axios_request'
import { ERROR } from '../../libs/utils'
import Caching from '../../libs/caching'
import { privateAPI } from '../end_point'
import { VERIFYPHONE, VERIFY_EMAIL_OTP } from '../actions_const'
import { getCookie } from '../../libs/cookies'

export const requestOTP = (phoneNumber, callback) => {
  return dispatch => {
    dispatch({ type: VERIFYPHONE.IS_PROCESS })
    setTimeout(() => {
      if (!phoneNumber) {
        if (typeof callback === 'function') callback({ code: 'oauth2/argument-phone-error', message: 'Argument phone error' })
        return dispatch({
          type: VERIFYPHONE.VERIFY_OTP_ERROR,
          data: { code: 'oauth2/argument-phone-error', message: 'Argument phone error' }
        })
      } else {
        axios({
          method: 'post',
          url: `${privateAPI}/otp/requestOTP`,
          data: { phoneNumber: phoneNumber }
        }).then(res => {
          if (!res.data || !res.data.result) {
            if (typeof callback === 'function') callback((res.data.error) || 'res error', null)
            dispatch({ type: VERIFYPHONE.PHONE_REQUEST_OTP_ERROR, data: res.data })
            ERROR('Error response', res.data.error)
          } else {
            coolDown(dispatch)
            if (typeof callback === 'function') callback(null, res.data.data)
            dispatch({ type: VERIFYPHONE.PHONE_REQUEST_OTP, data: res.data.data })
          }
        }).catch(err => {
          if (typeof callback === 'function') callback({ code: 'oauth2/operation-error', massage: 'Operation error' })
          dispatch({
            type: VERIFYPHONE.VERIFY_OTP_ERROR,
            data: { code: 'oauth2/operation-error', massage: 'Operation error' }
          })
        })
      }
    }, 500)
  }
}

function coolDown(dispatch) {
  let cooldown = 60
  let key = 'OTP_Cooldown'

  clearInterval(Caching.getCaching(key))
  Caching.clearCaching(key)

  dispatch({ type: VERIFYPHONE.SET_RESEND_OTP_COOLDOWN, data: cooldown })
  Caching.setCaching(key, setInterval(() => {
    cooldown = cooldown - 1
    dispatch({ type: VERIFYPHONE.SET_RESEND_OTP_COOLDOWN, data: cooldown })
    if (cooldown === 0) clearInterval(Caching.getCaching(key))
  }, 1000)
  )
}

export const verifyOTP = (phoneNumber, refCode, otp, callback) => {
  return dispatch => {
    dispatch({ type: VERIFYPHONE.IS_PROCESS })
    if (!otp) {
      if (typeof callback === 'function') callback({ code: 'oauth2/argument-otp-error', message: 'Argument OTP' }, null)
      return dispatch({
        type: VERIFYPHONE.VERIFY_OTP_ERROR,
        data: { code: 'oauth2/argument-otp-error', message: 'Argument OTP' }
      })
    }
    else {
      axios({
        method: 'post',
        url: `${privateAPI}/otp/verifyOTP`,
        data: { phoneNumber: phoneNumber, refCode: refCode, otp: otp.trim() }
      }).then(res => {
        if (!res.data || !res.data.result) {
          if (typeof callback === 'function') callback((res.data.error) || 'res error', null)
          return dispatch({ type: VERIFYPHONE.VERIFY_OTP_ERROR, data: res.data && res.data.error })
        }
        else {
          if (typeof callback === 'function') callback(null, res)
          dispatch({ type: VERIFYPHONE.VERIFY_OTP, data: res.data })
        }
      }).catch(err => {
        if (typeof callback === 'function') callback({ code: 'oauth2/operation-error', massage: 'Operation error' }, null)
        dispatch({
          type: VERIFYPHONE.VERIFY_OTP_ERROR,
          data: { code: 'oauth2/operation-error', massage: 'Operation error' }
        })
      })
    }
  }
}

export const verifySMSOTPLogin = ({ phoneNumber, refCode, otp, uuid }, callback) => {
  return dispatch => {
    dispatch({ type: VERIFYPHONE.IS_PROCESS })
    if (!otp) {
      if (typeof callback === 'function') callback({ code: 'oauth2/argument-otp-error', message: 'Argument OTP' }, null)
      return dispatch({
        type: VERIFYPHONE.VERIFY_OTP_ERROR,
        data: { code: 'oauth2/argument-otp-error', message: 'Argument OTP' }
      })
    }
    else {
      axios({
        method: 'post',
        url: `${privateAPI}/otp/verifyOTPLogin`,
        headers: { Authorization: getCookie("_token") },
        data: { phoneNumber: phoneNumber, refCode: refCode, otp: otp.trim(), uuid }
      }).then(res => {
        if (!res.data || !res.data.result) {
          if (typeof callback === 'function') callback((res.data.error) || 'res error', null)
          return dispatch({ type: VERIFYPHONE.VERIFY_OTP_ERROR, data: res.data && res.data.error })
        }
        else {
          if (typeof callback === 'function') callback(null, res)
          dispatch({ type: VERIFYPHONE.VERIFY_OTP, data: res.data })
        }
      }).catch(err => {
        if (typeof callback === 'function') callback({ code: 'oauth2/operation-error', massage: 'Operation error' }, null)
        dispatch({
          type: VERIFYPHONE.VERIFY_OTP_ERROR,
          data: { code: 'oauth2/operation-error', massage: 'Operation error' }
        })
      })
    }
  }
}

export const sendEmailOTP = ({ email, lang }, callback) => {
  return (dispatch) => {
    dispatch({ type: VERIFY_EMAIL_OTP.SEND_EMAIL_IS_PROCESS });
    axios({
      method: "post",
      url: `${privateAPI}/emailOtp/requestEmailOTP`,
      headers: { Authorization: getCookie("_token") },
      data: { email: email, lang: lang },
    })
      .then((res) => {
        if (!res.data || !res.data.result) {
          dispatch({
            type: VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_ERROR,
            data: res.data && res.data.error,
          });
        } else {
          coolDown(dispatch);
          if (typeof callback === "function") callback(null, res.data?.data);
          dispatch({
            type: VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_SUCCESS,
            data: res.data?.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_ERROR,
          data: { code: "oauth2/operation-error", massage: "Operation error" },
        });
      });
  };
};

export const verifyEmailOTP = ({ email, refCode, otp, uuid }, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: VERIFY_EMAIL_OTP.VERIFY_IS_PROCESS });
    axios({
      method: "post",
      url: `${privateAPI}/emailOtp/verifyEmailOTP`,
      headers: { Authorization: getCookie("_token") },
      data: {
        email: email,
        refCode: refCode,
        otp: otp,
        uuid: uuid,
      },
    })
      .then((res) => {
        if (!res.data || !res.data.result) {
          if (typeof callback === "function") callback(res.data.error);
          dispatch({
            type: VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_ERROR,
            data: res.data && res.data.error,
          });
        } else {
          const business_list = getState().business.list.map((business) => {
            if (business.business_code === getState().business.current.business_code) {
              return {
                ...business,
                otp_verify: true,
              }
            }
            return business;
          })
          if (typeof callback === "function") callback(null, business_list);
          dispatch({
            type: "UPDATE_VERIFY_EMAIL_OTP_SUCCESS",
            data: business_list,
          });
        }
      })
      .catch((err) => {
        if (typeof callback === "function") callback(err);
        dispatch({
          type: VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_ERROR,
          data: { code: "oauth2/operation-error", massage: "Operation error" },
        });
      });
  };
};

export const resetErrorEmailOTP = () => {
  return (dispatch) => {
    dispatch({ type: VERIFY_EMAIL_OTP.RESET_ERROR });
  };
};


export const resetErrorSMSOTP = () => {
  return (dispatch) => {
    dispatch({ type: VERIFYPHONE.RESET_ERROR_SMS_OTP });
  };
};