import { VERIFY_EMAIL_OTP, VERIFYPHONE } from '../actions_const'

let initState = {
  isProcess: false,
  isError: false,
  emailOTP: {
    isLoading: false,
    isProcess: false,
    isError: false,
    refCode: '',
    error: {},
  },
  cooldown: 0,
  refCode:'',
  error: {},
}

const ReducerVerifyOTP = (state = initState, action) => {
  switch (action.type) {
    case VERIFYPHONE.IS_PROCESS:
      return Object.assign({}, state, {
        isProcess: true,
        isError: false,
      })
    case VERIFYPHONE.SET_RESEND_OTP_COOLDOWN:
      return Object.assign({}, state, {
        cooldown: action.data
      })
    case VERIFYPHONE.PHONE_REQUEST_OTP:
      return Object.assign({}, state, {
        isProcess: false,
        isError: false,
        refCode: action.data.refCode || state.refCode || '',
      })
    case VERIFYPHONE.PHONE_REQUEST_OTP_ERROR:
      return Object.assign({}, state, {
        isProcess: false,
        isError: true,
        error: action.data.error || {}
      })
    case VERIFYPHONE.VERIFY_OTP_ERROR:
      return Object.assign({}, state, {
        isProcess: false,
        isError: true,
        error: action.data || {}
      })
    case VERIFYPHONE.VERIFY_OTP:
      return Object.assign({}, state, {
        isProcess: false,
        isError: false,
        refCode: action.data,
      })
    case VERIFYPHONE.CLEAR_REF_CODE:
      return Object.assign({}, state, {
        refCode: '',
      })

    case VERIFYPHONE.RESET_ERROR_SMS_OTP:
      return Object.assign({}, state, {
        error: {},
        isError: false,
        isProcess: false, 
      })

    case VERIFY_EMAIL_OTP.SEND_EMAIL_IS_PROCESS:
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          isLoading: true,
          refCode: '',
          error: {},
        })
      })
    case VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_SUCCESS:
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          isLoading: false,
          isProcess: false,
          refCode: action.data.refCode || '',
          error: {},
        })
      })
    case VERIFY_EMAIL_OTP.SEND_EMAIL_OTP_ERROR: 
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          isLoading: false,
          isProcess: false,
          error: action.data || {},
        })
      })
    case VERIFY_EMAIL_OTP.VERIFY_IS_PROCESS:
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          isProcess: true,
          error: {},
        })
      })
    case VERIFY_EMAIL_OTP.UPDATE_VERIFY_EMAIL_OTP_SUCCESS:
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          isProcess: false,
          error: {},
        })
      })
    case VERIFY_EMAIL_OTP.RESET_ERROR:
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          error: {},
        })
      })
    case VERIFY_EMAIL_OTP.CLEAR_REF_CODE:
      return Object.assign({}, state, {
        emailOTP: Object.assign({}, state.emailOTP, {
          refCode: '',
        })
      })
    default:
      return state
  }
}

export default ReducerVerifyOTP;