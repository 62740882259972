import React, { useState, useEffect } from "react";
import "./OtpSection.css";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { Title2 } from "../../components/Font";
import { nl2br } from "../../libs/nl2br";

function OtpSectionSMS(props) {
  let { updateOtp, otp } = props;
  const [thisotp, setOtp] = useState(otp);
  const { error, isProcess } = useSelector((state) => state.verifyotp);
  const { dictionary } = useSelector((state) => state.language);

  useEffect(() => {
    setOtp(otp)
  }, [otp])

  function otpChange(newvalue) {
    setOtp(newvalue);
    updateOtp(newvalue);
  }

  const pasteOTP = (event) => {
    const data = event.clipboardData.getData('text');
    setOtp(data);
    updateOtp(data);
  }

  return (
    <div style={{ marginTop: 40 }}>
      <OtpInput
        containerStyle="otp-input"
        value={thisotp}
        onChange={otpChange}
        onPaste={pasteOTP}
        numInputs={5}
        inputType="number"
        inputStyle="number"
        renderInput={(props) => <input disabled={isProcess} {...props} />}
        renderSeparator={<span style={{ width: 10 }} />}
      />
      <Title2
        danger className="d-flex justify-content-center align-items-center" style={{ height: 40 }}
      >
        {nl2br(dictionary[error?.code])}
      </Title2>
    </div>
  );
}

export default OtpSectionSMS;
