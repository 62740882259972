import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import Cookies from 'js-cookie';
import { DEBUG } from '../../libs/utils';
import { userLogout, cancelAuthStateChange } from '../../redux/actions/action_permission';
import { TitleMain1, Title2 } from '../../components/Font';
import { ContainerWizard, WizardImageLogo, WizardBox, WizardBoxDetail, BusinessAvatar, BusinessListItem, LogoutSection, SendAgainText } from './selectBusiness.style';
import PointSpotLogo from '../../assets/img/logo.svg';
import { selectBusinessDropdownlist } from '../../redux/actions/action_business';
import OtpSMSAuthen from './OtpSMSAuthen';
import OtpEmailAuthen from './OtpEmailAuthen';
import { getCookie } from '../../libs/cookies';
import { requestOTP, resetErrorEmailOTP, resetErrorSMSOTP, sendEmailOTP } from '../../redux/actions/action_verify_otp';
import prepareText from '../../libs/prepareText';
import { VERIFY_EMAIL_OTP, VERIFYPHONE } from '../../redux/actions_const';

export default function SelectBusiness() {
  const dispatch = useDispatch();
  const { list, current, isFromDropdown } = useSelector((state) => state.business);
  const { dictionary, locale } = useSelector((state) => state.language);
  const { isLogin, tabUUIDStore } = useSelector((state) => state.permission);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [mainTitle, setMainTitle] = useState(dictionary.choose_your_business);
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const loginTabUUID = getCookie('_tabUUID');
  const tabUUID = sessionStorage.getItem('tabUUID') || tabUUIDStore;
  const [typeOTP, setTypeOTP] = useState('off');
  const { profile } = useSelector((state) => state.permission);
  const firstSelectBusiness = useRef(false);

  const handleSelectBusiness = useCallback((business_info) => {
    if (!business_info) return;
    let { business_code, two_fa, otp_verify } = business_info;
    if (Object.values(two_fa).some((val) => val === true) && !otp_verify) {
      setIsShowOTP(true);
      setMainTitle(dictionary.confirm_sending_otp);
      dispatch(resetErrorSMSOTP());
      dispatch(resetErrorEmailOTP());
      if (two_fa.sms) {
        if (!business_info.phone_otp || !/^(0[1689])[0-9]{8}$/.test(business_info.phone_otp)) {
          setTypeOTP('not_support_otp');
      } else {
          setTypeOTP('sms');
          if(!firstSelectBusiness.current) {
            dispatch(requestOTP(business_info.phone_otp.replace(/^0/, '+66')));
            firstSelectBusiness.current = true;
          }
        }
      } else if (two_fa.email) {
        setTypeOTP('email');
        if(!firstSelectBusiness.current) {
          dispatch(sendEmailOTP({ email: profile.email, lang: locale }));
          firstSelectBusiness.current = true;
        }
      }
    }
    dispatch(selectBusinessDropdownlist(business_code));
  }, [dispatch]);

  useEffect(() => {
    if (currentBusiness) {
      handleSelectBusiness(currentBusiness);
    }
  }, [currentBusiness, handleSelectBusiness]);

 
  useEffect(() => {
    if (isLogin) {
      if (tabUUID !== loginTabUUID) {
        dispatch(cancelAuthStateChange());
      } else if ((list.length === 1 || isFromDropdown) && Object.values(current?.two_fa || {}).some((val) => val === true) && !current?.otp_verify) {
        setCurrentBusiness(current);
      }
    } else {
      if (tabUUID !== loginTabUUID) {
        dispatch(cancelAuthStateChange());
      } else if (!isFromDropdown) {
        logout();
      } else if (isFromDropdown && Object.values(current?.two_fa || {}).some((val) => val === true) && !current?.otp_verify) {
        setCurrentBusiness(current);
      }
    }
  }, [isLogin, isFromDropdown, current, loginTabUUID, dispatch]);

  const logout = () => { 
    setIsLoading(true);
    DEBUG('logout');
    localStorage.clear();
    Cookies.remove('_token');
    Cookies.remove('_email');
    dispatch(userLogout());
  };

  const backToSelectBusiness = () => {
    dispatch(resetErrorEmailOTP());
    dispatch({ type: VERIFY_EMAIL_OTP.CLEAR_REF_CODE });
    dispatch({ type: VERIFYPHONE.CLEAR_REF_CODE });
    setIsShowOTP(false);
    setMainTitle(dictionary.choose_your_business);
  };

  return (
    <div>
      {isLoading ? <Loading /> : (
        <ContainerWizard className='justify-content-center'>
          <div className='px-3 d-flex flex-column align-items-center justify-content-center'>
            <WizardImageLogo alt='' src={PointSpotLogo} />
            <TitleMain1 bold className='mx-2'>{typeOTP !== 'not_support_otp' ? mainTitle : ''}</TitleMain1>
            <WizardBox>
              {isShowOTP && currentBusiness ? (
                typeOTP === 'sms' ? (
                  <OtpSMSAuthen business_info={currentBusiness} />
                ) : typeOTP === 'email' ? (
                  <OtpEmailAuthen business_info={currentBusiness} />
                ) : (
                  <div className='w-100 text-center my-5'>
                    <Title2 style={{ fontWeight: 'bold', marginBottom: 10 }}>
                      {dictionary.cannot_verify_identity}
                    </Title2>
                    <Title2>
                      {currentBusiness?.phone_otp 
                        ? prepareText(dictionary.mobile_number_cannot_otp, [
                            currentBusiness.phone_otp.replace(/(\d{2})\d{4}(\d{3})/, '$1****$2'),
                          ])
                        : dictionary.phone_number_not_found}
                    </Title2>
                    <Title2>{dictionary.contact_admin_for_edit}</Title2>
                  </div>
                )
              ) : (
                <WizardBoxDetail>
                  {list.map((info, index) => (
                    <BusinessListItem key={index} onClick={() => setCurrentBusiness({...info})}>
                      <div style={{ width: '50px', height: '50px' }}>
                        <BusinessAvatar alt='' src={info.business_logo_url} />
                      </div>
                      <div className='pl-4'>
                        <Title2>{info.business_name}</Title2>
                      </div>
                    </BusinessListItem>
                  ))}
                </WizardBoxDetail>
              )}
              <LogoutSection className='border-top'>
                <SendAgainText onClick={() => (list.length === 1 || !isShowOTP ? logout() : backToSelectBusiness())}>
                  {list.length === 1 || !isShowOTP ? dictionary.sign_in_with_another_account : dictionary.back}
                </SendAgainText>
              </LogoutSection>
            </WizardBox>
          </div>
        </ContainerWizard>
      )}
    </div>
  );
}
