import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import _ from 'lodash';
import { Title2 } from '../../Font';
import RangePointFromTo from './RangePointFromTo';
import RangePointLessEqual from './RangePointLessEqual';
import RangePointGreaterEqual from './RangePointGreaterEqual';
import { getTargetMemberPointTotal, setTargetMemberTotal } from '../../../redux/actions/action_broadcast';
import { usePrevious } from '../../../libs/hooks/usePrevious';
import { toastError } from '../../ToastComponent/ToastError';
import { toCurrency } from '../../../libs/currency';

export default function TargetMemberPoints({
	target,
	noRequest,
	disabled,
	showAsLabel,
	isEditBroadcast
}) {

	const isMounted = useRef()
	const dictionary = useSelector((state) => state.language.dictionary)
	const { business_code } = useSelector((state) => state.business.current)
	const dispatch = useDispatch()
	const timeoutChangePoint = useRef()
	const [select, setSelect] = useState(target?.point_flow || 'point_from_to')
	const [pointRange, setPointRange] = useState({
		start: _.get(target, 'point_start', ''),
		end: _.get(target, 'point_end', '')
	})
	const pointRangePrevious = usePrevious(pointRange)
	const [invalid, setInvalidPoint] = useState(false)
	const invalidPrevious = usePrevious(invalid)
	const pointFlow = ['point_from_to', 'point_greater_equal', 'point_less_equal']

	useEffect(() => {
		if (!noRequest) {
			if (
				(JSON.stringify(pointRangePrevious) !== JSON.stringify(pointRange) || isEditBroadcast)
				&& !invalid
			) {
					timeoutChangePoint.current = setTimeout(() => {
						dispatch(
							getTargetMemberPointTotal(
								{
									business_code,
									point_flow: select,
									point_start: parseInt(pointRange.start),
									point_end: pointRange.end ? parseInt(pointRange.end) : null
								},
								(err) => {
									if (err) toastError(dictionary.error);
								}
							)
						);
					}, 1000);
				}
		} else {
			dispatch(setTargetMemberTotal({
				target: 'member_points',
				target_data: {
					point_flow: select,
					point_start: parseInt(pointRange.start),
					point_end: pointRange.end ? parseInt(pointRange.end) : null
				}
			}))
		}

		return () => {
			clearTimeout(timeoutChangePoint.current)
		}
	}, [dispatch, business_code, pointRange, pointRangePrevious, invalid, select, noRequest, dictionary])

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true
			dispatch(
				setTargetMemberTotal({
					target: 'member_points'
				})
			);
		}

		if (
			(invalid && (invalid !== invalidPrevious)) ||
			(select === 'point_from_to' &&
				(pointRange.start === '' || pointRange.end === '')) ||
			(['point_greater_equal', 'point_less_equal'].indexOf(select) > -1 &&
				(pointRange.start === '' && pointRange.end === ''))
		) {
			dispatch(
				setTargetMemberTotal({
					target: 'member_points'
				})
			);
		}
	}, [dispatch, invalid, invalidPrevious, pointRange, select])

	const handleChangeSelect = (e) => {
		let { value } = e.target
		setSelect(value)
		setPointRange({
			start: '',
			end: ''
		})
		callbackHandleInvalid(false)
	}

	const callbackHandleInvalid = useCallback((invalid) => {
		setInvalidPoint(invalid)
	}, [])

	const callbackHandleChangePoint = useCallback((pointRange) => {
		setPointRange({
			start: pointRange.start,
			end: pointRange.end
		})
	}, [])

	const RenderTargetAsLabel = () => {
		switch (select) {
			case 'point_from_to':
				return (
					<Title2 className='pl-3' secondary>{dictionary.point_from_to} {toCurrency(pointRange.start)} {dictionary.to} {pointRange.end} {dictionary.points.toLowerCase()}</Title2>	
				)
			case 'point_greater_equal':
				return (
					<Title2 className='pl-3' secondary>{dictionary.point_greater_equal} {toCurrency(pointRange.start)} {dictionary.points.toLowerCase()}</Title2>	
				)
			case 'point_less_equal':
				return (
					<Title2 className='pl-3' secondary>{dictionary.point_less_equal} {toCurrency(pointRange.end)} {dictionary.points.toLowerCase()}</Title2>	
				)
			default:
				break;
		}
	}

	return (
		<FormGroup>
			<Title2 className='pb-2' bold><span className='text-danger'>* </span>{dictionary.amount_points}</Title2>
			{
				showAsLabel
				? <RenderTargetAsLabel/>
				:	<Row className='align-items-start ml-0'>
						<Col xs={10} sm={pointFlow.indexOf(select) > 0 ? 4 : 3} className='p-0 pr-2'>
							<Input
								type="select"
								value={select}
								onChange={handleChangeSelect}
								className="p-1"
								disabled={disabled}
							>
								{
									pointFlow.map((value, index) => {
										return <option value={value} key={`${index}_${value}`}>{dictionary[value]}</option>
									})
								}
							</Input>
						</Col>
						{
							select === 'point_from_to' &&
							<RangePointFromTo
								start={pointRange.start}
								end={pointRange.end}
								onChangePoint={callbackHandleChangePoint}
								onSetInvalid={callbackHandleInvalid}
								isInvalid={invalid}
								disabled={disabled}
							/>
						}
						{
							select === 'point_greater_equal' &&
							<RangePointGreaterEqual
								start={pointRange.start}
								end={pointRange.end}
								onChangePoint={callbackHandleChangePoint}
								onSetInvalid={callbackHandleInvalid}
								isInvalid={invalid}
								disabled={disabled}
							/>
						}
						{
							select === 'point_less_equal' &&
							<RangePointLessEqual
								start={pointRange.start}
								end={pointRange.end}
								onChangePoint={callbackHandleChangePoint}
								onSetInvalid={callbackHandleInvalid}
								isInvalid={invalid}
								disabled={disabled}
							/>
						}
					</Row>
			}
		</FormGroup>
	)
}