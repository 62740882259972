import {
  GET_PRIVILEGE_AND_INVITE_LIST,
  PRIVILEGE_AND_INVITE_LIST_IS_PROCESS,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_FAIL,
  ADD_INVITATION_SUCCESS,
  ADD_INVITATION_FAIL,
  CHANGE_USER_PRIVILEGE_SUCCESS,
  CHANGE_USER_PRIVILEGE_FAIL,
  PRIVILEGE_AND_INVITE_LIST_LOADING,
  INITIAL_BUSINESS_STATE,
  ADD_CUSTOM_ROLE_SUCCESS,
  ADD_CUSTOM_ROLE_FAIL,
  EDIT_CUSTOM_ROLE_SUCCESS,
  EDIT_CUSTOM_ROLE_FAIL,
} from "../actions_const";

const initState = {
  isLoading: true,
  isProcess: false,
  privilegeList: [],
  privilegeListDetail: [],
  credentialList: [],
  inviteList: [],
  roles: [],
  userInfo: {},
};

const ReducerAdmin = (state = initState, action) => {
  switch (action.type) {
    case INITIAL_BUSINESS_STATE:
      return initState;
    case PRIVILEGE_AND_INVITE_LIST_LOADING:
      return initState;
    case GET_PRIVILEGE_AND_INVITE_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        privilegeList: action.data.data.privilege || [],
        inviteList: action.data.data.invite || [],
        roles: action.data.data.roles,
        credentialList: action.data.data.credential || [],
        privilegeListDetail: action.data.data.rolePermiss.concat(action.data.data.roleBusinessPermiss) || [],
      });
    case DELETE_INVITATION_SUCCESS:
      let nextState = state;

      if (action.data.action === "invitation")
        nextState = Object.assign({}, state, {
          isProcess: false,
          inviteList: state.inviteList.filter(
            (value) => value.email !== action.data.data
          ),
        });
      else if (action.data.action === "privilege")
        nextState = Object.assign({}, state, {
          isProcess: false,
          privilegeList: state.privilegeList.filter(
            (value) => value.user_id !== action.data.data
          ),
        });
      else if (action.data.action === "custom_role")
        nextState = Object.assign({}, state, {
          isProcess: false,
          roles: state.roles.filter(
            (value) => value.privilege_group_id !== action.data.data
          ),
          privilegeListDetail: state.privilegeListDetail.filter(
            (value) => value.privilege_group_id !== action.data.data
          ),
        });
      return nextState;
    case DELETE_INVITATION_FAIL:
      return Object.assign({}, state, {
        isProcess: false,
      });
    case PRIVILEGE_AND_INVITE_LIST_IS_PROCESS:
      return Object.assign({}, state, {
        isProcess: true,
      });
    case ADD_INVITATION_SUCCESS:
      return Object.assign({}, state, {
        isProcess: false,
        inviteList: state.inviteList.concat(action.data),
      });
    case ADD_INVITATION_FAIL:
      return Object.assign({}, state, {
        isProcess: false,
      });
    case CHANGE_USER_PRIVILEGE_FAIL:
      return Object.assign({}, state, {
        isProcess: false,
      });
    case CHANGE_USER_PRIVILEGE_SUCCESS:
      return {
        ...state,
        privilegeList: state.privilegeList.map((value) =>
          value.user_id === action.data.user_id
            ? {
                ...value,
                privilege_group_id: parseInt(action.data.fk_privilege_group_id),
                transfer_limit: parseInt(action.data.transfer_limit),
                display_name: action.data.display_name || "",
              }
            : value
        ),
      };
    case ADD_CUSTOM_ROLE_SUCCESS:
      return Object.assign({}, state, {
        isProcess: false,
        roles: state.roles.concat(action.data.data),
        privilegeListDetail: state.privilegeListDetail.concat(
          action.data.role
        ),
      });
    case ADD_CUSTOM_ROLE_FAIL:
      return Object.assign({}, state, {
        isProcess: false,
      });
    case EDIT_CUSTOM_ROLE_SUCCESS:
      if (action.data && action.data.length > 0) {

        state.roles.forEach((val, index) => {
          if(val.privilege_group_id === action.dataName.privilege_group_id){
            Object.assign(
              state.roles[index],
              {
                privilege_group_name: action.dataName.privilege_group_name,
                self_transactions_history: action.dataName.self_transactions_history
              }
            )
          }
        })
        action.data.forEach((value, key) => {
          let index = state.privilegeListDetail.findIndex(
            (val) =>
              val.privilege_group_id === value.privilege_group_id &&
              val.menu_key === value.menu_key
          );
          return (state.privilegeListDetail[index] = Object.assign(
            state.privilegeListDetail[index],
            {
              privilege_group_name: action.dataName.privilege_group_name,
              permission_view: action.data[key].permission_view,
              permission_create: action.data[key].permission_create,
              permission_edit: action.data[key].permission_edit,
              permission_delete: action.data[key].permission_delete,
              permission_export: action.data[key].permission_export,
            }
          ));
        })
      } else {
        state.roles.forEach((val, index) => {
          if(val.privilege_group_id === action.dataName.privilege_group_id){
            Object.assign(
              state.roles[index],
              {
                privilege_group_name: action.dataName.privilege_group_name,
                self_transactions_history: action.dataName.self_transactions_history
              }
            )
          }
        })
        state.privilegeListDetail.forEach((val, index) => {
          if(val.privilege_group_id === action.dataName.privilege_group_id){
            Object.assign(
              state.privilegeListDetail[index],
              {
                privilege_group_name: action.dataName.privilege_group_name
              }
            )
          }
        })
      }
      return Object.assign({}, state, {
        isProcess: false,
      });
    case EDIT_CUSTOM_ROLE_FAIL:
      return Object.assign({}, state, {
        isProcess: false,
      });
    default:
      return state;
  }
};
export default ReducerAdmin;